import React from "react";
import Col from "react-bootstrap/Col";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { Link } from "gatsby";
import { SafeHtmlParser } from "./safeHtmlParser";

const ImageSideContainer = ({
  title,
  image,
  imageAlt,
  order,
  text,
  imagePadding,
  mPage,
  imageStyle,
  ctaButton,
}) => {
 
  return (
    <section className="py-5 py-xl-0">
      <Container>
        <Row className={`${mPage ? "d-lg-none" : "d-none"}`}>
          <Col>
            <h2 className={`pb-4 `}>{title}</h2>
          </Col>
        </Row>
        <Row className="align-items-center ">
          <Col
            className={`mx-0 px-lg-0 ${imagePadding}`}
            xs={{ span: 12, order: "first" }}
            lg={{ span: 6, order: order }}
          >
            <GatsbyImage
              className={imageStyle ? imageStyle : "w-100"}
              image={image}
              alt={imageAlt}
            />
          </Col>
          <Col
            className={`px-3 ${
              order === "last" ? "pe-lg-5 ps-lg-0" : "ps-lg-5 pe-lg-0"
            }`}
            xs={12}
            lg={6}
          >
            <h2 className={`pb-4 ${mPage ? "d-none d-lg-block" : ""}`}>
              {title}
            </h2>
            <SafeHtmlParser
                htmlContent={text}
              />
            {ctaButton && ctaButton.url && (
              <Row>
                <Col className="text-center text-md-start">
                  <Button
                    style={{ borderRadius: "0px" }}
                    variant="secondary"
                    as={Link}
                    to={ctaButton.url}
                    className="py-3 w-100 w-md-auto mt-3 mb-4 mb-md-0 px-5 me-4"
                    target={
                      ctaButton.target ??
                      "_self"
                    }
                  >
                    {ctaButton.title}
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ImageSideContainer;
