import React from "react";
import { Container } from "react-bootstrap";
import { SafeHtmlParser } from "./safeHtmlParser";

const RepairGuaranteeSection = ({ title, content }) => {
  return (
    <section className="pt-5 bg-white">
      <Container>
        <h2 className="my-4 fs-1">{title}</h2>
        <SafeHtmlParser htmlContent={content} />
      </Container>
    </section>
  );
};

export default RepairGuaranteeSection;
